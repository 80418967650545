import styled from '@emotion/styled';
import { IconButton } from '@tablecheck/tablekit-react';
import { inspect } from '@xstate/inspect';
import { Interpreter } from 'xstate';

const storageKey = 'portal-devtools.xstate.inspect';

export function initXStateDevtools(): void {
  const isInspectorActive = window.localStorage.getItem(storageKey) === 'true';
  if (isInspectorActive) {
    inspect({
      iframe: false, // open in new window
      url: 'https://stately.ai/viz?inspect',
      serialize: (key: string, value: unknown) => {
        if (key.match(/Ref$/g)) {
          console.warn(`xstate inspector dom node "${key}"`, value);
          return `<<omitted dom node>>`;
        }
        return value;
      },
    });

    // Sometimes this can get a little slow so is disabled by default
    // set this to true if you don't know which machine to debug or you want to
    // see all the machines in the inspector.
    // To inspect just one machine, add `devTools: true` to the options of `useMachine/useInterpret` or `interpret`;
    // `intepret` => https://xstate.js.org/docs/packages/xstate-inspect/#installation
    // `useMachine\useInterpret` => https://xstate.js.org/docs/packages/xstate-react/#api (look for "Interpreter Options")
    Interpreter.defaultOptions.devTools = false;
  }
}

const isInspectorActive = window.localStorage.getItem(storageKey) === 'true';

const Button = styled(IconButton)`
  position: fixed;
  bottom: 56px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--surface-raised-active);
  & > span {
    color: var(--brand-primary);
    font-weight: bold;
    padding: 2px;
  }

  [dir='ltr'] & {
    right: 2px;
  }
  [dir='rtl'] & {
    left: 2px;
  }
`;

export function XStateDevToolsToggle(): JSX.Element {
  return (
    <Button
      data-variant="bare"
      onClick={() => {
        window.localStorage.setItem(
          storageKey,
          isInspectorActive ? 'false' : 'true',
        );
        window.location.reload();
      }}
    >
      <svg
        width="48"
        height="48"
        viewBox="0 0 400 400"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Icon">
          <path
            id="logomark"
            fill={isInspectorActive ? 'var(--success)' : 'var(--text)'}
            fillRule="evenodd"
            stroke="none"
            d="M 160.048401 62 L 200.370178 102.226257 L 313.959961 215.546524 L 313.959961 215.570801 C 315.837982 217.466064 317 220.069107 317 222.944962 C 317 226.157791 315.548462 229.029297 313.267426 230.954041 L 313.267426 230.954041 L 208.213959 335.758667 C 203.882339 340.080444 196.858887 340.080444 192.527267 335.758667 L 192.527267 335.758667 L 87.249039 230.730255 C 82.916985 226.408463 82.916985 219.401657 87.249039 215.080292 L 87.249039 215.080292 L 152.153763 150.328903 L 159.394135 157.552994 C 169.631516 167.119476 186.170258 195.360092 164.012238 218.95105 C 161.84642 221.111282 161.84642 224.615128 164.012238 226.775803 L 164.012238 226.775803 L 196.49025 259.17688 C 198.656494 261.337555 202.16777 261.337555 204.334015 259.17688 L 204.334015 259.17688 L 236.707687 226.879898 C 237.766693 225.86937 238.504425 224.471573 238.504425 222.894211 C 238.504425 221.359802 237.836243 219.9711 236.82724 218.96579 L 236.82724 218.96579 L 209.069931 191.274673 L 209.153397 191.191833 L 160.173599 142.328064 C 137.904709 120.111938 137.77951 84.216553 160.048401 62 L 160.048401 62 Z M 244.500214 62 C 260.23996 62 273 74.759521 273 90.5 C 273 106.240051 260.23996 119 244.500214 119 C 228.76004 119 216 106.240051 216 90.5 C 216 74.759521 228.76004 62 244.500214 62 Z"
          />
        </g>
      </svg>
      <span>Portal</span>
    </Button>
  );
}
