import { css } from '@emotion/react';

export const cssVariables = css`
  :root {
    --portal-header-height: 40px;
    --zindex-help-panel: 1000;
    --zindex-portal-dialog: 1100;
    --zindex-alert: 2000;
    --dialog-overlay-background: rgba(24, 24, 24, 0.15);
  }
`;
