export const APP_THEMES = ['classic', 'light', 'dark'] as const;
export type AppTheme = (typeof APP_THEMES)[number];
export const themeLocalStorageKey = 'tablecheck-merchant-app-theme';

/**
 * Called to change/set the current theme
 * @param theme
 */
export type AppThemeFunction = (theme: AppTheme) => void;

declare global {
  interface Window {
    /**
     * Use this to register listeners to the application theme
     * @param listener
     */
    registerAppThemeListener: (listener: AppThemeFunction) => VoidFunction;
  }
}
