import { addYears, differenceInMinutes, set, startOfMinute } from 'date-fns';

export const lastLoginTimeKey = 'tablecheck-session-last-login';

export function setSharedSessionCookie(
  key: string,
  value: string,
  expirationDate: Date,
) {
  document.cookie = `${key}=${value}; expires=${expirationDate.toUTCString()}; path=/; domain=${
    import.meta.env.VITE_SESSION_DOMAIN
  }`;
}

export function clearLastLoginTime(): void {
  setSharedSessionCookie(lastLoginTimeKey, '', new Date(0));
}

export function setLastLoginTime(time = new Date().getTime()): void {
  const expirationDate = addYears(new Date(), 1);
  setSharedSessionCookie(lastLoginTimeKey, time.toString(), expirationDate);
}

export function getLastLoginTime(): number | null {
  const cookies = document.cookie.split('; ');
  for (const cookie of cookies) {
    const [key, value] = cookie.split('=');
    if (value && key === lastLoginTimeKey) {
      return parseInt(value, 10) || null;
    }
  }
  return null;
}

function getSessionExpiryTime() {
  return startOfMinute(set(new Date(), { hours: 5, minutes: 0 }));
}

export function getMinutesUntilExpiry(): number {
  return differenceInMinutes(getSessionExpiryTime(), new Date());
}

function getTimes() {
  const currentTime = startOfMinute(new Date()).getTime();
  const warningTime = startOfMinute(
    set(new Date(), { hours: 4, minutes: 45 }),
  ).getTime();
  const expiresTime = getSessionExpiryTime().getTime();
  const lastLoginTime = startOfMinute(
    getLastLoginTime() ?? new Date(),
  ).getTime();
  return {
    currentTime,
    expiresTime,
    warningTime,
    lastLoginTime,
  };
}

export function getSessionValidity(): 'valid' | 'warn' | 'expired' {
  const { currentTime, expiresTime, warningTime, lastLoginTime } = getTimes();
  if (currentTime >= expiresTime && lastLoginTime < warningTime)
    return 'expired';
  if (currentTime >= warningTime && lastLoginTime < warningTime) return 'warn';
  return 'valid';
}
