import { useMediaQuery } from '@react-hookz/web';

import { AppName } from './constants';

const MOBILE_BREAKPOINT =
  'only screen and (max-width: 576px) and (orientation:portrait), only screen and (max-height: 576px) and (max-width: 800px) and (orientation:landscape)';

const defaultEnvironment = import.meta.env.VITE_DEPLOY_TARGET ?? 'development';

export function getWindowTarget(
  appName: AppName | `${AppName}`,
  environment: string = defaultEnvironment,
): string {
  return `tablecheck_${environment}_${appName}`;
}

const appPaths = {
  manager:
    import.meta.env.VITE_APP_PATH_MANAGER ||
    'https://manager.app.staging-qa.tablecheck.com/',
  'manager-mobile':
    import.meta.env.VITE_APP_PATH_MANAGER_MOBILE ||
    'https://mobile.app.staging-qa.tablecheck.com/',
  insight:
    import.meta.env.VITE_DEPLOY_TARGET === 'china'
      ? import.meta.env.VITE_INSIGHT_HOST
      : '/insight',
  settings:
    import.meta.env.VITE_DEPLOY_TARGET === 'china'
      ? import.meta.env.VITE_SETTINGS_HOST
      : '/settings',
  home: '/',
};

export function getAppPath(appName: `${AppName}`): string {
  return appPaths[appName];
}

export function useResolveAppPath(appName: `${AppName}`): string {
  const isMobile = useMediaQuery(MOBILE_BREAKPOINT) ?? false;
  if (appName === AppName.Manager && isMobile) {
    return getAppPath('manager-mobile');
  }
  return getAppPath(appName);
}

/**
 * For applications within the SSPA structure, use this on the window to ensure compatability.
 * for other apps
 * @param appName
 * @param subPath
 * @param environment
 */
export function openPortalApp(
  appName: `${AppName}`,
  subPath?: string,
  environment: string = defaultEnvironment,
): void {
  window.open(
    `${getAppPath(appName)}${subPath ? `/${subPath}` : ''}`.replace(
      /\/+/gi,
      '/',
    ),
    getWindowTarget(appName, environment),
  );
}

declare global {
  interface Window {
    /**
     * Shared function to allow switching of apps using centralised logic
     * @param appName
     * @param subPath Path after the app name, should include `/` at the start
     */
    openPortalApp: (appName: `${AppName}`, subPath?: string) => void;
  }
}

window.openPortalApp = (appName, subPath) =>
  openPortalApp(appName, subPath, defaultEnvironment);
