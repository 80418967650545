import { css, Global } from '@emotion/react';
import { LocaleCode, locales } from '@tablecheck/locales';
import { themedCss } from '@tablecheck/tablekit-core';
import { ThemeProvider } from '@tablecheck/tablekit-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { cssVariables } from '~/cssVariableExports';
import { useAppTheme } from '~/utils/appTheme/exports';
import { useCurrentApp } from '~/utils/useIsCurrentApp';

import { themeViewTransition } from './themeViewTransitions';

import '~/utils/appTheme/internal';

const isCypress = // eslint-disable-next-line @typescript-eslint/naming-convention
  typeof (window as { Cypress?: unknown }).Cypress !== 'undefined';

export function InternalThemeProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const appTheme = useAppTheme();
  const [, { language }] = useTranslation();
  const currentApp = useCurrentApp();

  return (
    <ThemeProvider
      theme={appTheme === 'dark' ? 'dark' : 'light'}
      lang={language}
      isRtl={
        locales[language as LocaleCode]?.dir === 'rtl' &&
        currentApp !== 'insight'
      }
    >
      <Global
        styles={css`
          ${themedCss({
            selector: '',
            light: css`
              --app-preview-background: #eae6f4;
              --app-preview-surface: #ffffff;
              --portal-tablecheck-icon: var(--primary);
            `,
            dark: css`
              --app-preview-background: #370466;
              --app-preview-surface: #eae6f4;
              --portal-tablecheck-icon: #ffffff;
            `,
          })}
          // CLEANUP_START Fallback compatibility with old themes, remove after settings upgraded
          ${themedCss({
            selector: '',
            light: css`
              --secondary: rgba(41, 41, 41, 1);
              --secondary-active: rgba(75, 75, 75, 1);
              --secondary-hover: rgba(102, 102, 102, 1);
              --secondary-surface: rgba(249, 249, 249, 1);
            `,
            dark: css`
              --secondary: rgba(255, 255, 255, 1);
              --secondary-active: rgba(206, 206, 206, 1);
              --secondary-hover: rgba(229, 229, 229, 1);
              --secondary-surface: rgba(41, 41, 41, 1);
            `,
          })}
          :root {
            --text-contrast: var(--text-secondary);
          }
          // CLEANUP_END

          body {
            background-color: var(--surface-low);
          }
          ${isCypress
            ? css`
                *,
                * > *,
                body {
                  animation-duration: 0s !important;
                  transition-duration: 0s !important;
                }
              `
            : ''}
          ${cssVariables}
          ${themeViewTransition}
        `}
      />
      {children}
    </ThemeProvider>
  );
}
