import styled from '@emotion/styled';
import { TransitionStatus } from 'react-transition-group/Transition';

const FADE_TIME = 200;

export const TRANSITION_PROPS = {
  appear: true,
  timeout: FADE_TIME,
};

export const AppContainer = styled.div`
  padding-top: var(--portal-header-height);
  transition: all 0.3s ease-in-out;
  min-height: 100vh;
  // this is a fix due to height: auto; not being inheritable by child nodes
  // needed for min-height: 100% to work on child nodes
  height: 1px;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: var(--spacing-l4);
`;

export const Fade = styled.div<{ 'data-state': TransitionStatus }>`
  transition: ${FADE_TIME}ms ease-in-out;
  opacity: 0;
  &[data-state='entered'] {
    opacity: 1;
  }
`;

export const FixedFade = styled(Fade)`
  position: fixed;
  top: var(--portal-header-height);
  left: 0;
  right: 0;
  min-height: calc(100vh - var(--portal-header-height));
`;
