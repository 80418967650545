import {
  getAxios,
  registerAuthListener,
} from '@tablecheck/react-sso-authenticator';
import * as React from 'react';

import type { ReportSentryErrorType } from '../sentry';

import { AppTheme, AppThemeFunction, themeLocalStorageKey } from './constants';
import { getCurrentAppTheme } from './exports';

const listeners: AppThemeFunction[] = [
  (theme) => {
    document.documentElement.dataset.portalTheme = theme;
  },
];
const persistanceListeners: typeof listeners = [];

export function useAppThemePersistance(
  reportSentryError: ReportSentryErrorType,
  userUrl = `${import.meta.env.VITE_API_PATH}current_user`,
): void {
  React.useEffect(() => {
    const callback: AppThemeFunction = (theme) => {
      if (theme === getCurrentAppTheme()) return;
      getAxios()
        .put(userUrl, {
          app_theme: theme,
        })
        .catch((error) => {
          console.error(error);
          reportSentryError(error, {
            extra: {
              info: 'Update user app_theme failed',
            },
          });
        });
    };
    persistanceListeners.push(callback);
    return () => {
      const index = persistanceListeners.indexOf(callback);
      if (index === -1) return;
      persistanceListeners.splice(index, 1);
    };
  }, [userUrl]);
}

if (!window.registerAppThemeListener)
  window.registerAppThemeListener = (listener) => {
    listeners.push(listener);
    return () => {
      listeners.splice(listeners.indexOf(listener), 1);
    };
  };

let setCurrentAppThemeRef: AppThemeFunction = () => {
  // as we set the current theme on the logged in user, this should be set after the user is logged in
  throw new Error(
    `set current app theme is called before the user is logged in`,
  );
};

declare global {
  interface Document {
    /**
     * Manual type shim until the API is properly supported and TS adds it
     * @param updateCallback
     * @returns
     */
    startViewTransition: (updateCallback: () => Promise<void> | void) => never;
  }
}

export const setCurrentAppTheme: AppThemeFunction = (...args) => {
  if (!document.startViewTransition) setCurrentAppThemeRef(...args);
  else document.startViewTransition(() => setCurrentAppThemeRef(...args));
};

function castToTheme(value: string | null | undefined): AppTheme | undefined {
  if (value && ['classic', 'dark', 'light'].includes(value))
    return value as AppTheme;
  return undefined;
}

window.addEventListener('storage', (event) => {
  const { key, newValue } = event;
  const value = castToTheme(newValue);
  if (key !== themeLocalStorageKey || !value) return;
  listeners.forEach((listener) => listener(value));
});

// setup the set function once the user is correctly logged in
registerAuthListener(() => {
  setCurrentAppThemeRef = (theme) => {
    persistanceListeners.forEach((listener) => listener(theme));
    window.localStorage.setItem(themeLocalStorageKey, theme);
    listeners.forEach((listener) => listener(theme));
  };
});
