import { LocaleCode } from '@tablecheck/locales';
import i18next from 'i18next';
import * as React from 'react';
import { initReactI18next } from 'react-i18next';

import type { ReportSentryErrorType } from '~/utils/sentry';

import { initLanguageChangeHandler, triggerLanguageChange } from './exports';
import enLocale from './locales/en.json';

const localeImports = import.meta.glob<boolean, string, typeof enLocale>(
  './locales/*.json',
);

const namespaces = Object.keys(enLocale) as never as (keyof typeof enLocale)[];

i18next.use(initReactI18next);

async function importLanguage<Namespace extends (typeof namespaces)[number]>(
  language: LocaleCode,
  namespace: Namespace,
  callback: (errorValue: unknown, translations: unknown) => void,
): Promise<void> {
  if (language === LocaleCode.English) {
    callback(null, enLocale[namespace]);
    return;
  }
  const localeImport = localeImports[`./locales/${language}.json`];
  if (!localeImport) {
    callback(`No locale file found for ${language}`, {});
    return;
  }

  try {
    const locale = await localeImport();
    callback(null, locale[namespace]);
  } catch (error) {
    callback(error, {});
  }
}

i18next.use({
  type: 'backend',
  read<Namespace extends (typeof namespaces)[number]>(
    language: LocaleCode,
    namespace: Namespace,
    callback: (errorValue: unknown, translations: unknown) => void,
  ) {
    void importLanguage(language, namespace, callback);
  },
});

i18next
  .init({
    lng: LocaleCode.English,
    ns: namespaces,
    fallbackLng: [LocaleCode.English],
    defaultNS: 'translations',
    load: 'currentOnly',

    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react!!
    },
  })
  .catch((error) => {
    console.error('Failed to initialize i18next', error);
  });

export const i18n = i18next;

export function useLocalePersistance(
  reportSentryError: ReportSentryErrorType,
  userPermissionsApiUrl = `${import.meta.env.VITE_API_PATH}current_user`,
): void {
  React.useEffect(() => {
    initLanguageChangeHandler(reportSentryError, userPermissionsApiUrl);
  }, []);
}

i18next.on('languageChanged', (newLanguage: LocaleCode) => {
  triggerLanguageChange(newLanguage);
});
