import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { configureDefaults } from '@tablecheck/tablekit-react';
import { createRoot } from 'react-dom/client';

import { App } from '~/App';
import { initDevTools } from '~/App/DevTools';
import { initSentry } from '~/utils/sentry';

import '~/utils/appLink';

const isolatedCache = createCache({
  key: 'portal',
});

initSentry();

configureDefaults({
  controlSize: 'medium',
});

initDevTools();

if (import.meta.env.VITE_DEPLOY_TARGET === 'production') {
  const GA_ID = 'UA-151908461-1';
  if (!document.querySelector('script#load-gtag')) {
    const gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute('id', 'load-gtag');
    gaScript.setAttribute(
      'src',
      `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`,
    );
    if (document.documentElement?.firstChild) {
      document.documentElement.firstChild.appendChild(gaScript);
    }
  }

  const gaScript2 = document.createElement('script');
  gaScript2.innerText = `if (!window.dataLayer) { window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${GA_ID}', { send_page_view: false }); }`;

  if (document.documentElement?.firstChild) {
    document.documentElement.firstChild.appendChild(gaScript2);
  }
}

let rootElement = document.getElementById('root');
if (!rootElement) {
  rootElement = document.createElement('div');
  document.body.appendChild(rootElement);
}

const root = createRoot(rootElement);

// eslint-disable-next-line no-console
console.info(`Application init: portal @ ${import.meta.env.VITE_APP_VERSION}`);

root.render(
  <CacheProvider value={isolatedCache}>
    <App />
  </CacheProvider>,
);
