import { Authenticator, setConfig } from '@tablecheck/react-sso-authenticator';
import { Alert } from '@tablecheck/tablekit-react';
import * as React from 'react';
import { I18nextProvider } from 'react-i18next';

import { i18n } from '~/i18n';
import { reportSentryError } from '~/utils/sentry';
import { clearLastLoginTime, setLastLoginTime } from '~/utils/sessionExpiry';

import DevToolsImport from './DevTools';
import { InternalThemeProvider } from './ThemeProvider';
import { ErrorWrapper } from './styled';

// preloading the app registry
setTimeout(() => {
  import('~/appRegistry');
}, 0);

const DevTools = import.meta.env.DEV ? DevToolsImport : () => null;

setConfig({
  '@tablecheck-sso/access-token-uri': import.meta.env.VITE_SSO_ACCESS_TOKEN_URI,
  '@tablecheck-sso/authorization-uri': import.meta.env
    .VITE_SSO_AUTHORIZATION_URI,
  '@tablecheck-sso/client-id': import.meta.env.VITE_SSO_CLIENT_ID,
  '@tablecheck-sso/client-secret': import.meta.env.VITE_SSO_SECRET,
  '@tablecheck-sso/logout-uri': import.meta.env.VITE_SSO_LOGOUT_URI,
  '@tablecheck-sso/method': import.meta.env.VITE_SSO_METHOD,
  publicUri: import.meta.env.VITE_PUBLIC_URL,
});

const isCypress = // eslint-disable-next-line @typescript-eslint/naming-convention
  typeof (window as { Cypress?: unknown }).Cypress !== 'undefined';

if (isCypress) {
  document.getElementById('portal-page-loader-overlay')?.remove();
}
const Authenticated = React.lazy(() =>
  import('./Authenticated').then((e) => ({ default: e.Authenticated })),
);

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}
export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    reportSentryError(error, {
      extra: { errorInfo },
    });
  }

  render(): JSX.Element | React.ReactNode {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return 'Fatal Error with Authenticator';
    }
    return children;
  }
}

export function App(): JSX.Element | null {
  return (
    <I18nextProvider i18n={i18n}>
      <InternalThemeProvider>
        <ErrorBoundary>
          <Authenticator
            onLogin={() => {
              setLastLoginTime();
            }}
            onLogout={() => {
              clearLastLoginTime();
            }}
            renderApp={({ state, error }) => {
              if (state === 'error' && error instanceof Error) {
                // This catches the case where the user gets redirected back to `/auth-redirect` with a bad state
                // or the url is somehow bad
                window.location.replace('/');
              } else if (state === 'error') {
                return (
                  <ErrorWrapper>
                    <Alert data-variant="warning">
                      <span>{String(error) || 'UNKNOWN ERROR'}</span>
                    </Alert>
                  </ErrorWrapper>
                );
              }
              if (state === 'authenticated')
                return (
                  <React.Suspense fallback={null}>
                    <Authenticated />
                  </React.Suspense>
                );
              return <>{null}</>;
            }}
          />
        </ErrorBoundary>
        <DevTools />
      </InternalThemeProvider>
    </I18nextProvider>
  );
}
