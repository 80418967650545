import { css } from '@emotion/react';

export const themeViewTransition = css`
  ::view-transition-group(root) {
    animation-duration: 800ms;
  }
  ::view-transition-new(root),
  ::view-transition-old(root) {
    mix-blend-mode: normal;
  }

  ::view-transition-new(root) {
    animation-name: reveal-classic;
  }

  ::view-transition-old(root),
  [data-portal-theme='dark']::view-transition-old(root) {
    animation: none;
  }
  [data-portal-theme='dark']::view-transition-new(root) {
    animation-name: reveal-dark;
  }

  ::view-transition-old(root),
  [data-portal-theme='light']::view-transition-old(root) {
    animation: none;
  }
  [data-portal-theme='light']::view-transition-new(root) {
    animation-name: reveal-light;
  }

  @media (prefers-reduced-motion: reduced) {
    ::view-transition-group(root) {
      animation-duration: 0 !important;
    }
  }

  @keyframes reveal-dark {
    from {
      clip-path: polygon(-30% 0, -30% 0, -15% 100%, -10% 115%);
    }
    to {
      clip-path: polygon(-30% 0, 130% 0, 115% 100%, -10% 115%);
    }
  }

  @keyframes reveal-light {
    from {
      clip-path: polygon(130% 0, 130% 0, 115% 100%, 110% 115%);
    }
    to {
      clip-path: polygon(130% 0, -30% 0, -15% 100%, 110% 115%);
    }
  }

  @keyframes reveal-classic {
    from {
      clip-path: circle(0%);
    }
    to {
      clip-path: circle(130%);
    }
  }
`;
