import type { Hub } from '@sentry/browser';
import type { CaptureContext } from '@sentry/types';
import axios from 'axios';

export enum SentryTag {
  HelpCenter = 'help_center',
}

const getTraceRates = (): {
  tracesSampleRate: number;
  sampleRate: number;
} => {
  if (
    import.meta.env.VITE_DEPLOY_TARGET === 'production' ||
    import.meta.env.VITE_DEPLOY_TARGET === 'china'
  ) {
    return {
      tracesSampleRate: 0.6,
      sampleRate: 1.0,
    };
  }

  if (import.meta.env.DEV) {
    return {
      sampleRate: 0.0,
      tracesSampleRate: 0.0,
    };
  }

  return {
    sampleRate: 1.0,
    tracesSampleRate: 1.0,
  };
};

let loadPromise: Promise<Hub> | null = null;

async function setupSentry() {
  const {
    BrowserClient,
    Hub,
    defaultIntegrations,
    defaultStackParser,
    makeFetchTransport,
  } = await import('@sentry/browser');
  const { Integrations } = await import('@sentry/tracing');
  const client = new BrowserClient({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [...defaultIntegrations, new Integrations.BrowserTracing()],
    autoSessionTracking: true,
    sendClientReports: true,
    environment: import.meta.env.VITE_DEPLOY_TARGET,
    release: `portal-frontend@${import.meta.env.VITE_APP_VERSION}`,
    stackParser: defaultStackParser,
    transport: makeFetchTransport,
    ...getTraceRates(),
  });
  const sentryHub = new Hub(client);
  sentryHub.startSession({ ignoreDuration: true });
  sentryHub.captureSession();
  return sentryHub;
}

export function initSentry() {
  loadPromise = setupSentry();
}

export const reportSentryError = (
  exception: Parameters<Hub['captureException']>[0],
  captureContext?: CaptureContext,
) => {
  if (axios.isCancel(exception)) {
    return;
  }
  if (!loadPromise) {
    console.error(
      'Trying to report error before sentry initialized!',
      exception,
      captureContext,
    );
    return;
  }

  void loadPromise.then(
    (sentryHub) =>
      void sentryHub.captureException(exception, { captureContext }),
  );
};

export type ReportSentryErrorType = typeof reportSentryError;

export const configureSentryScope: Hub['configureScope'] = (callback) => {
  if (!loadPromise) {
    console.error('Trying to configure sentry before initialized!');
    return;
  }

  void loadPromise.then((sentryHub) => sentryHub.configureScope(callback));
};
