import * as React from 'react';
import * as sspa from 'single-spa';

import { AppName } from './constants';

export function useMountedApps(): string[] {
  const [apps, setApps] = React.useState(() => sspa.getMountedApps());
  React.useEffect(() => {
    const handler = () => setApps(sspa.getMountedApps());
    window.addEventListener('single-spa:app-change', handler);
    window.addEventListener('single-spa:first-mount', handler);
    return () => {
      window.removeEventListener('single-spa:app-change', handler);
      window.removeEventListener('single-spa:first-mount', handler);
    };
  }, [setApps]);
  return apps;
}

export function useIsCurrentApp(appName: AppName | `${AppName}`): boolean {
  return useMountedApps().includes(appName);
}

export function useCurrentApp(): AppName {
  const mountedApps = useMountedApps();

  return React.useMemo(
    () =>
      [AppName.Manager, AppName.Settings, AppName.Insight].reduce<AppName>(
        (result, appName) => {
          if (mountedApps.includes(appName)) {
            return appName;
          }
          return result;
        },
        AppName.Home,
      ),
    [mountedApps],
  );
}
