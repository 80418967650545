export const SSPA_DIV_ID = 'sspa-content';

export enum AppName {
  Manager = 'manager',
  ManagerMobile = 'manager-mobile',
  Insight = 'insight',
  Settings = 'settings',
  Home = 'home',
}

export enum RoutePath {
  Help = 'help',
  Settings = 'settings',
}
